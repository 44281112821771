import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 5×2 \\@90% 1RM`}</p>
    <p>{`Barbell Rows 5×2\\@90% 1RM`}</p>
    <p>{`then, 21-15-9 reps each of:`}</p>
    <p>{`S2OH (135/95)`}</p>
    <p>{`T2B`}</p>
    <p>{`for time.`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`30 Calorie Ski Erg`}</p>
    <p>{`30 Box Jump Overs (24/20)`}</p>
    <p>{`for time.`}</p>
    <h4><strong parentName="h4"><em parentName="strong">{`*`}{`The CrossFit Open starts February 21st! To prepare we’ll be throwing in some past Open WOD’s in our class programming. Register for the Open at: Games.CrossFit.com.`}</em></strong></h4>
    <h4><strong parentName="h4">{`*`}{`CrossFit the Ville’s Barbell Club will start back up this Saturday.  If you’d like to improve your Clean & Jerk and Snatch these classes are for you.`}</strong>{` `}<strong parentName="h4">{`Classes are an hour and a half each and offered Saturday’s (11:15-12:45) and Sunday’s (1:45-3:00) each weekend after our CrossFit classes. You can pay per class `}<em parentName="strong">{`(\\$15 for members, \\$20 for non members)`}</em>{` or pay upfront for a discounted rate for the entire online program plus all classes `}<em parentName="strong">{`(\\$120 for members, \\$200 for couples)`}</em>{`.  Since we missed one session last Sunday due to the weather we’ll take that class off the total if you are paying for the entire program.`}</strong></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      